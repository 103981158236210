import React from 'react'

const Header = () => {
  return (
    <div>
      <div className="container-fluid title-header">
        <h1 className='text-center'>
          <strong>
            Koneko Gameplays
          </strong>
        </h1>
      </div>
    </div>
  )
}

export default Header
