import React, {useEffect, useState} from 'react'
import TwitchCollection from './TwitchCollection'
import TwitchVideo from './TwitchVideo'
import {db, doc, onSnapshot } from '../firebase'

const Broadcast = () => {

  const [status, setstatus] = useState(false)
  const [statusLabel, setstatusLabel] = useState('Desconectado')
  const [statusClass, setstatusClass] = useState('red')

  useEffect(() => {
    const statusStream = onSnapshot(doc(db, "/settings/", "stream"), (doc) => {
      console.log("Current data: ", doc.data());
      if(doc.data().status=="online"){
        setstatus(true);
        setstatusLabel('Transmitiendo en vivo')
      }else{
        setstatus(false);
        setstatusLabel('Desconectado')
      }
    });
  
    return () => {
      
    };
  }, [status, statusLabel, statusClass]);
  

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <div className={statusClass}>
            <h6 className='d-flex align-items-center'>
              <span className="material-icons">
                cast_connected
              </span>  <strong>{statusLabel}</strong>
            </h6>
          </div>
        </div>
      </div>
      {
        status ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <TwitchVideo />
            </div>
          </div>
        ) : null
      }
      {
        status ? null : (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <TwitchCollection />
            </div>
          </div>
        )
      }
    </>
  )
}

export default Broadcast
