import React from 'react'
import { TwitchPlayer } from 'react-twitch-embed';


const TwitchCollection = () => {
  return (
    <div className='mb-5'>
      <TwitchPlayer collection="Ybi4f0KUyRbDYQ" hideControls={false} />
    </div>
  )
}

export default TwitchCollection
