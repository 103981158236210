import React from 'react'
import Broadcast from './Broadcast'

const Content = () => {
  return (
    <>
      <div className="container-fluid content mb-5">
        {/* stream live */}
        <div className='container-fluid w-100 mb-5'>
          <Broadcast />
        </div>
        {/* Video on demand */}
        <div className="row">

        </div>
      </div>
    </>
  )
}

export default Content
