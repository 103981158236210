import React from "react";

const Footer = () => {
  return (
    <>
      <div className="fixed-bottom footer">
        <h6 className="d-flex align-items-center justify-content-center text-center">
          <span className="material-icons">sports_esports</span> 
          Made by Koneko
          <span className="material-icons">sports_esports</span>
        </h6>
      </div>
    </>
  );
};

export default Footer;
