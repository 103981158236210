import Content from "./components/Content";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <div className="container-fluid main">
        <Header />
        <hr />
        <Content />
      </div>
      <Footer />
    </div>
  );
}

export default App;
