import React from 'react'
import { TwitchEmbed } from 'react-twitch-embed';

const TwitchVideo = () => {
  return (
    <div className='mb-5'>
      <TwitchEmbed
        channel="koneko960"
        id="koneko960"
        theme="dark"
        muted
        onVideoPause={() => console.log(':(')}
        height='calc(100vh - 10rem)'
        width="90vw"
      />
    </div>
  )
}

export default TwitchVideo
